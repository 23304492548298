const footerDropdown = () => {
  const footerToggleBtn = document.querySelector(
    ".footer__info__contact-title .footer__toggle"
  );

  const footerDp = document.querySelector(".footer__info__contact-dp");

  if (!footerToggleBtn || !footerDp) return;

  const footerToggleBtn_Href = () => {
    if (window.matchMedia("(max-width: 600px)").matches) {
      footerToggleBtn.setAttribute("href", "https://www.iedc.in.gov/contact");
      footerToggleBtn.setAttribute("target", "_blank");
    }

    if (!window.matchMedia("(max-width: 600px)").matches) {
      footerToggleBtn.setAttribute("href", "javascript:void(0)");
      footerToggleBtn.removeAttribute("target");
    }
  };

  footerToggleBtn_Href();

  // -------------------------
  window.addEventListener("resize", function () {
    footerToggleBtn_Href();
  });

  // -------------------------
  footerToggleBtn.addEventListener("click", function () {
    if (window.matchMedia("(max-width: 600px)").matches) return;

    footerToggleBtn
      .closest(".footer__info__contact-title")
      ?.classList.toggle("active");
    footerDp.classList.toggle("active");
  });
};

export default footerDropdown;
