import dropdowns_Toggle from "./components/dropdown/dropdownsToggle";
import footerDropdown from "./components/footerDropdown/footerDropdown";
import nav from "./components/nav/nav";
import lazyLoad from "./utility/lazyLoad/lazyLoad";

const init = () => {
  dropdowns_Toggle();
  lazyLoad();
  nav();
  footerDropdown();
};

init();
